/* eslint-disable no-undef */
import 'vite/modulepreload-polyfill';

// Constants
const SESSION_KEYS = {
  hasBeenAsked: 'hasBeenAskedOrRedirectedAlready',
  isPreferred: 'isPreferredStore',
  userCountry: 'userCountryCode',
};

/**
 * Store Selection and Geolocation Utilities
 */

export const getUserCountry = async () => {
  const cachedCountry = sessionStorage.getItem(SESSION_KEYS.userCountry);
  if (cachedCountry) return cachedCountry;

  try {
    const response = await fetch('/browsing_context_suggestions.json');
    const data = await response.json();
    const countryCode = data.detected_values.country.handle;

    if (!window.Shopify?.designMode) {
      sessionStorage.setItem(SESSION_KEYS.userCountry, countryCode);
    }

    return countryCode;
  } catch (error) {
    console.error('Error fetching country:', error);
    return null;
  }
};

export const getCurrentStoreRegion = (currentStoreUrl) => {
  if (currentStoreUrl) {
    const regions = window.storeSelectorConfig?.regions || {};

    for (const [regionKey, regionData] of Object.entries(regions)) {
      if (regionData.suggestedStoreUrl?.toLowerCase().trim() === currentStoreUrl.toLowerCase().trim()) {
        return regionKey;
      }
    }
  }
  return null;
};

export const isCountryInRegion = (countryCode, regionKey) => {
  if (!countryCode || !regionKey) return false;
  const region = window.storeSelectorConfig?.regions[regionKey];
  return region?.countryCodes.includes(countryCode) || false;
};

export const isPreferredStore = (userCountry, currentStoreUrl) => {
  if (!userCountry || !currentStoreUrl) return false;
  const currentRegion = getCurrentStoreRegion(currentStoreUrl);
  return currentRegion ? isCountryInRegion(userCountry, currentRegion) : false;
};

export const getSuggestedStore = ({ currentStoreUrl, currentUserCountryCode, currentStoreCountryCode, isTestMode = false, isStagingMode = false }) => {
  const regions = window.storeSelectorConfig.regions;
  for (const [regionKey, regionData] of Object.entries(regions)) {
    if (regionData.countryCodes.includes(currentUserCountryCode)) {
      return {
        suggestedStoreUrl: regionData.suggestedStoreUrl ? regionData.suggestedStoreUrl : currentStoreUrl,
        suggestedStoreCountryCode: regionKey,
        suggestedStoreRegion: regionData.displayName,
        currentStoreUrl: currentStoreUrl,
        currentStoreCountryCode: currentStoreCountryCode,
        currentUserCountryCode: currentUserCountryCode,
        isStoreLocator: false,
        isTestMode: isTestMode,
        isStagingMode: isStagingMode,
        popoverDisabled: regionData.popoverDisabled,
        showRedirectPopup: regionData.suggestedStoreUrl.toLowerCase().trim() !== currentStoreUrl.toLowerCase().trim() ? true : false,
      };
    }
  }

  // If no region matches the user's country, return store locator
  return {
    suggestedStoreUrl: window.currentShop.storeLocatorUrl,
    suggestedStoreCountryCode: 'EU',
    suggestedStoreRegion: 'Europe',
    currentStoreUrl: currentStoreUrl,
    currentStoreCountryCode: currentStoreCountryCode,
    currentUserCountryCode: currentUserCountryCode,
    isStoreLocator: true, // Set to true because no region matched
    isTestMode: isTestMode,
    isStagingMode: isStagingMode,
    popoverDisabled: false,
    showRedirectPopup: true,
  };
};

/**
 * Cart Utilities
 */
export const fetchProductsInCart = async () => {
  try {
    const response = await fetch(`${window.routes.cart_url}.js`);
    const data = await response.json();

    const productsInCart = {
      regularProducts: [],
      swatchProducts: [],
      swatchProductsLimit: [window.swatches.buyLimit],
      swatchProductsLimitReached: [],
    };

    let swatchProductsCount = 0;

    data.items.forEach((item) => {
      const productTypeLowercased = item.product_type.toLowerCase();
      if (productTypeLowercased === window.swatches.productTypeUpholstery || productTypeLowercased === window.swatches.productTypeMaterial) {
        swatchProductsCount += item.quantity;
        productsInCart.swatchProducts.push(item);
      } else {
        productsInCart.regularProducts.push(item);
      }
    });

    productsInCart.swatchProductsLimitReached.push(swatchProductsCount >= window.swatches.buyLimit);

    return productsInCart;
  } catch (error) {
    console.error('Error fetching cart:', error);
    return null;
  }
};

/**
 * Focus Management Utilities
 */
const trapFocusHandlers = {};

const getFocusableElements = (container) => {
  return Array.from(
    container.querySelectorAll(
      "summary, a[href], button:enabled, [tabindex]:not([tabindex^='-']), [draggable], area, input:not([type=hidden]):enabled, select:enabled, textarea:enabled, object, iframe",
    ),
  );
};

export const trapFocus = (container, elementToFocus = container) => {
  const elements = getFocusableElements(container);
  const first = elements[0];
  const last = elements[elements.length - 1];

  removeTrapFocus();

  trapFocusHandlers.focusin = (event) => {
    if (event.target !== container && event.target !== last && event.target !== first) return;
    document.addEventListener('keydown', trapFocusHandlers.keydown);
  };

  trapFocusHandlers.focusout = () => {
    document.removeEventListener('keydown', trapFocusHandlers.keydown);
  };

  trapFocusHandlers.keydown = (event) => {
    if (event.code.toUpperCase() !== 'TAB') return;

    if (event.target === last && !event.shiftKey) {
      event.preventDefault();
      first.focus();
    }

    if ((event.target === container || event.target === first) && event.shiftKey) {
      event.preventDefault();
      last.focus();
    }
  };

  document.addEventListener('focusout', trapFocusHandlers.focusout);
  document.addEventListener('focusin', trapFocusHandlers.focusin);

  elementToFocus.focus();

  if (elementToFocus.tagName === 'INPUT' && ['search', 'text', 'email', 'url'].includes(elementToFocus.type) && elementToFocus.value) {
    elementToFocus.setSelectionRange(0, elementToFocus.value.length);
  }
};

const removeTrapFocus = (elementToFocus = null) => {
  document.removeEventListener('focusin', trapFocusHandlers.focusin);
  document.removeEventListener('focusout', trapFocusHandlers.focusout);
  document.removeEventListener('keydown', trapFocusHandlers.keydown);

  if (elementToFocus) elementToFocus.focus();
};

/**
 * Script Loading Utility
 */
export const loadScript = (src, async = true, type = 'text/javascript') => {
  return new Promise((resolve, reject) => {
    try {
      const tag = document.createElement('script');
      const container = document.head || document.body;

      tag.type = type;
      tag.async = async;
      tag.src = src;

      tag.addEventListener('load', () => {
        resolve({ loaded: true, error: false, url: src });
      });

      tag.addEventListener('error', () => {
        reject({
          loaded: false,
          error: true,
          message: `Failed to load script with src ${src}`,
        });
      });

      container.appendChild(tag);
    } catch (error) {
      reject(error);
    }
  });
};

// Export constants
export const sessionKeys = SESSION_KEYS;
